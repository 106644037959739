@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

ul,
li {
	list-style: none;
}

input {
	border-radius: 0.5rem;
	border: 0.1rem solid grey;
}

body {
	display: flex;
	justify-content: center;
	font-family: 'Poppins', sans-serif;
}

.container {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
	border: 0.1rem solid rgb(197, 197, 197);
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: fit-content;
	margin: 1rem;
	padding: 2rem;
	text-align: center;
	overflow: auto;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.btn {
	display: inline-block;
	border: none;
	border-radius: 0.5rem;
	width: 6rem;
	margin-left: 1rem;
	padding: 0.7rem 1rem;
	background: #000;
	color: #fff;
	font-size: 1rem;
	font-weight: 700;
	font-family: inherit;
	text-decoration: none;
	transition: color, 500ms;
	cursor: pointer;
}

@media screen and (min-width: 1024px) {
	.container {
		width: 40rem;
	}
}

.btn:hover {
	background-color: rgb(51, 51, 51);
}

.btn-block {
	display: block;
	width: 10rem;
	margin: 0 auto;
}

.task {
	margin: 1rem 0;
	padding: 1rem 2rem;
	background: #f4f4f4;
	cursor: pointer;
}

.task.reminder {
	border-left: 0.5rem solid orange;
}

.task h3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.add-form {
	margin-bottom: 2rem;
}

.form-control {
	margin: 1rem 0;
}

.form-control input[type='text'] {
	width: 100%;
	height: 2.5rem;
	font-size: 1rem;
	text-align: center;
}

.form-control-check {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1.5rem;
}

.form-control-check input[type='checkbox'] {
	width: 1.2rem;
	height: 1.2rem;
	margin-left: 1rem;
	cursor: pointer;
}

.hidden-visually {
	position: absolute;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	width: 1px;
	height: 1px;
	white-space: nowrap;
}
